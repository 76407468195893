const theme = {
  colors: {
    white: "#fff",
    lighterGrey: "#F1F2F7",
    MenuTextColor: "#AEB2B7",
    jvrisAqua: "#1fb5ad",
    jvrisAquaDark: "#1C6E6A",
    darkBlue: "#1d2b36",
    darkerBlue: "#202f3c",
    lightGrey: "#eaeaec",
    grey: "#bebebe",
    gray: "#767676",
    bgTitlePage: "#e0e1e7",
    titlePage: "#222227",
    midnightBlue: "#32323A",
    darkGrey: "#28282e",
    darkerGrey: "#202025",
    softRed: "#D9534F",
    softGreen: "#5CB85C",
    softYellow: "#F0AD4E",
    mediumGrey: "#AEB2B7",
    bgCleanButton: "#53bee6",
  },
};

export default theme;
