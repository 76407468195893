import { Outlet } from "react-router-dom";
import * as S from "./styled";

export const PageBody = () => {
  return (
    <S.Wrapper>
      <S.FormContainer>
        <Outlet />
      </S.FormContainer>
    </S.Wrapper>
  );
};
