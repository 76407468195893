import { Suspense, lazy } from "react";
import Splash from "./pages/Splash";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageBody } from "./components/PageBody";
const PaymentRequirements = lazy(
  () => import("./pages/Dashboard/Reports/PaymentRequirements")
);
const DoeDistributions = lazy(
  () => import("./pages/Dashboard/Reports/DoeDistributions")
);
const OperatorProductivity = lazy(
  () => import("./pages/Dashboard/Reports/OperatorProductivity")
);
const DistributionsReport = lazy(
  () => import("./pages/Dashboard/Reports/Distributions")
);
const Login = lazy(() => import("./pages/Login"));
const MenuHeader = lazy(() => import("./components/MenuHeader"));
const Attorney = lazy(() => import("./pages/Dashboard/Management/Attorney"));
const Distributions = lazy(
  () => import("./pages/Dashboard/AccountingReports/Distributions")
);
const Management = lazy(
  () => import("./pages/Dashboard/AccountingReports/Management")
);
const AttorneyProductivity = lazy(
  () => import("./pages/Dashboard/Reports/AttorneyProductivity")
);
const AdvisorProductivity = lazy(() => import("./pages/Dashboard/Reports/AdvisorProductivity"));
const Audit = lazy(() => import("./pages/Dashboard/Reports/Audit"));
const RpvProductivity = lazy(() => import("./pages/Dashboard/Reports/RpvProductivity"));
const Cases = lazy(() => import("./pages/Dashboard/Reports/Cases"));
const ProceduralSheedNotClosed = lazy(
  () => import("./pages/Dashboard/AccountingReports/ProceduralSheedNotClosed")
);
const FinishedPieces = lazy(
  () => import("./pages/Dashboard/Reports/FinishedPieces")
);
const NotFound = lazy(() => import("./pages/NotFound"));
const DocEditor = lazy(() => import("./pages/DocEditor"));

export const Router = () => (
  <BrowserRouter>
    <Suspense fallback={<Splash />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MenuHeader />}>
          <Route path="procurador" element={<PageBody />}>
            <Route path="pecas" element={<DocEditor />} />
          </Route>
          <Route path="gerenciamento" element={<PageBody />}>
            <Route path="procurador" element={<Attorney />} />
          </Route>
        
          <Route path="relatorios" element={<PageBody />}>
            <Route path="rel-distribuicoes" element={<DistributionsReport />} />
            <Route
              path="rel-produtividade-procuradores"
              element={<AttorneyProductivity />}
            />
            <Route
              path="rel-produtividade-operadores"
              element={<OperatorProductivity />}
            />
            <Route path="rel-distribuicao-doe" element={<DoeDistributions />} />
            <Route path="rel-produtividade-assessor" element={<AdvisorProductivity />} />
            <Route path="rel-auditoria" element={<Audit />} />
            <Route path="rel-produtividade-rpv" element={<RpvProductivity />} />
            <Route path="rel-processos" element={<Cases />} />
          </Route>

          <Route path="relatorios-contadoria" element={<PageBody />}>
            <Route path="distribuicoes" element={<Distributions />} />
            <Route path="gerenciais" element={<Management />} />
            <Route
              path="rel-fichas-nao-encerradas-dcje"
              element={<ProceduralSheedNotClosed />}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
