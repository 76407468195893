import { createGlobalStyle } from "styled-components";
import OpenSans from "../fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
    font-family: 'Open Sans', sans-serif;
  }

  // increase performance rather google fonts link html
  @font-face {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100 900;
    font-stretch: 75% 125%;
    font-style: normal;
    src: url(${OpenSans}) format('truetype');
    font-display: swap;
  }
`;

export default GlobalStyle;
