import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Router } from "./Routes";
import GlobalStyle from "./globalStyle/styles";
import { ThemeProvider } from "styled-components";
import theme from "./globalStyle/theme";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  </QueryClientProvider>
);
reportWebVitals();
